import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  useMediaQuery,
  Container,
  Button,
  Grid,
  Link,
  FormHelperText,
  Hidden,
  IconButton,
  InputAdornment,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined'
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined'
import InfoIcon from '@material-ui/icons/Info'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
// eslint-disable-next-line import/no-extraneous-dependencies
import { I18n } from '@aws-amplify/core'
import Title from '../../components/common/text/Title'
import { useTrademarkContext, useLayoutContext } from '../../components/context'
import {
  mapIcon,
  brandTypes,
  ProductsAndServicesSearchField,
  FormField,
  FileUpload,
  BrandTypeModal,
} from '../../components'
import {
  kindOfPerson as kindOfPersonCatalog,
  PHYSICAL_PERSON,
  whatsappContactMessages,
  emailContactMessages,
  WHATSAPP_PHONE,
  MAILTO_ADDRESS,
} from '../../config'
import { isEmail } from '../../regex.validations'
import { useInfoModal } from '../../hooks'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  nextButtons: {
    marginTop: theme.spacing(2),
  },
  nextButtonContainer: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    alignItems: 'center',
  },
  nextButton: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1.5),
    },
  },
  noLabel: {
    marginTop: theme.spacing(1),
  },
  brandSelectIcon: {
    fontSize: theme.typography.fontSize,
    marginRight: theme.typography.fontSize,
  },
  formHelperText: {
    marginLeft: theme.spacing(1.75),
    marginRight: theme.spacing(1.75),
    cursor: 'pointer',
  },
  infoText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    textTransform: 'none',
    justifyContent: 'center',
    textAlign: 'center',
  },
  endAdornment: {
    marginRight: theme.spacing(1.5),
  },
}))

const BrandData = () => {
  const classes = useStyles()
  const theme = useTheme()

  const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'))

  const brandOptions = brandTypes.map((option) => {
    const { Icon } = mapIcon(option)
    return {
      value: option.id,
      label: option.name,
      icon: <Icon className={classes.brandSelectIcon} />,
    }
  })
  const kindOfPersonOptions = kindOfPersonCatalog.map((option) => {
    return {
      value: option.value,
      label: I18n.get(option.label),
    }
  })
  const {
    authenticated,
    currentUser,
    setWhatsappContactStatus,
    setEmailContactStatus,
    setContactSpeedDialEnabled,
  } = useLayoutContext()
  const {
    setCurrentStep,
    brand,
    setBrand,
    openCancelTradeMarkRegistrationModal,
    files,
    setFiles,
    setFilesUploaded,
    setPreApplicationID,
    setProductsServicesCreated,
  } = useTrademarkContext()
  const { register, handleSubmit, control, errors, watch, setValue } = useForm({
    defaultValues: {
      ...brand?.generalData,
    },
  })
  const applicantKind = watch('applicantKind')
  const [selectedBrandType, setSelectedBrandType] = useState(
    brandTypes.find((b) => b.id === brand?.generalData?.brandType)
  )
  const { openModal } = useInfoModal()

  useEffect(() => {
    if (currentUser) {
      setValue('email', currentUser.username)
    }
  }, [currentUser, setValue])

  useEffect(() => {
    setContactSpeedDialEnabled(true)
    const whatsapp = whatsappContactMessages.unauthenticatedUserBrandRegistration(
      brand?.generalData?.brandName,
      brand?.generalData?.email
    )

    const email = emailContactMessages.authenticatedUserBrandRegistration(
      brand?.generalData?.brandName,
      brand?.generalData?.email
    )

    setWhatsappContactStatus({
      number: WHATSAPP_PHONE,
      text: whatsapp,
    })

    setEmailContactStatus({
      email: MAILTO_ADDRESS,
      subject: email?.subject,
      body: email?.message,
    })
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    whatsappContactMessages,
    emailContactMessages,
    setWhatsappContactStatus,
    setEmailContactStatus,
  ])

  // eslint-disable-next-line no-unused-vars
  const onSubmit = async (data) => {
    setFiles(data?.brandFiles)
    setFilesUploaded(null)
    setPreApplicationID(null)
    setProductsServicesCreated(false)
    setBrand({ generalData: { ...brand?.generalData, ...data } })
    setCurrentStep((prevActiveStep) => prevActiveStep + 1)
  }

  const brandTypeAssigned = async (event) => {
    const { value } = event.target
    const selectBrandType = brandTypes.find((b) => b.id === value)
    setSelectedBrandType(selectBrandType)
  }

  const openEmailInformation = () => {
    openModal({
      title: 'Correo electrónico',
      content: (
        <div className={classes.infoText}>
          Tu corrreo electrónico nos servirá para mantenerte informado sobre el
          avance de tu solicitud.
        </div>
      ),
      confirmationText: 'Cerrar',
      cancellationText: null,
    })
  }

  const openInformationModal = ({ id, title, text, images }) => {
    openModal({
      title: '',
      content: (
        <BrandTypeModal id={id} title={title} text={text} images={images} />
      ),
      confirmationText: 'Cerrar',
      cancellationText: null,
    })
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.root}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Title>Registro de marca</Title>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                id="brandType"
                name="brandType"
                type="select"
                required
                variant="outlined"
                size="small"
                fullWidth
                className={clsx(classes.margin)}
                label="Tipo de marca"
                labelClassName={clsx(classes.margin, classes.noLabel)}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Selecciona un tipo de marca',
                  },
                }}
                errors={errors}
                options={brandOptions}
                // eslint-disable-next-line no-unused-vars
                renderValue={(selected) => {
                  // const brandType = brandTypes.find((b) => b.id === selected)
                  const { Icon: BrandIcon } = mapIcon(selectedBrandType)
                  return (
                    <span>
                      <BrandIcon className={classes.brandSelectIcon} />
                      {selectedBrandType.name}
                    </span>
                  )
                }}
                onChange={brandTypeAssigned}
                IconComponent={() => {
                  return null
                }}
                endAdornment={
                  <InputAdornment
                    position="end"
                    className={classes.endAdornment}>
                    <IconButton
                      aria-label="info"
                      size="small"
                      onClick={() => {
                        openInformationModal({
                          id: selectedBrandType.id,
                          title: selectedBrandType.name,
                          text: selectedBrandType.helperPopOverText,
                          images: selectedBrandType.helperImages,
                        })
                      }}>
                      <InfoIcon fontSize="inherit" />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                id="email"
                name="email"
                label="Correo electrónico"
                type="email"
                required
                disabled={authenticated}
                variant="outlined"
                size="small"
                fullWidth
                className={clsx(classes.margin, classes.noLabel)}
                inputRef={register({
                  required: {
                    value: true,
                    message: 'Ingresa tu correo electrónico',
                  },
                  minLength: {
                    value: 6,
                    message: 'Debe ser mayor o igual a 6 caracteres',
                  },
                  pattern: {
                    value: isEmail,
                    message: 'Debes ingresar un correo electrónico válido',
                  },
                })}
                errors={errors}
              />
              <FormHelperText
                classes={{
                  root: classes.formHelperText,
                }}>
                <Link onClick={openEmailInformation}>
                  ¿Por qué lo requerimos?
                </Link>
              </FormHelperText>
            </Grid>
            {selectedBrandType?.requireName ? (
              <Grid item xs={12} md={12}>
                <FormField
                  id="brandName"
                  name="brandName"
                  label="Nombre de tu marca"
                  required
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={clsx(classes.margin, classes.noLabel)}
                  inputRef={register({
                    required: {
                      value: true,
                      message: 'Ingresa el nombre de tu marca',
                    },
                  })}
                  errors={errors}
                  multiline
                  rows="4"
                />
              </Grid>
            ) : null}
            {selectedBrandType?.requireFiles ? (
              <Grid item xs={12} md={12}>
                <FileUpload
                  id="brandFiles"
                  name="brandFiles"
                  label="Archivos adjuntos"
                  required
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={clsx(
                    classes.margin,
                    classes.noLabel,
                    classes.brandFilesSelection
                  )}
                  acceptedFiles={['image/gif']}
                  filesLimit={5}
                  maxFileSize={5 * 1000 * 1000}
                  dropzoneText="Arrastra y suelta una imagen aquí o haz clic. Tamaño máximo 5MB por imagen. Máximo 5 archivos de imagen."
                  previewText="Archivos seleccionados"
                  showAlerts={false}
                  showPreviews
                  showPreviewsInDropzone={false}
                  showFileNames
                  showFileNamesInPreview
                  useChipsForPreview={false}
                  onChange={(f) => console.log('Files:', f)}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Selecciona al menos un archivo de imagen',
                    },
                    validate: (value) => {
                      return (
                        (Array.isArray(value) && value?.length > 0) ||
                        'Selecciona al menos un archivo de imagen'
                      )
                    },
                  }}
                  errors={errors}
                  initialFiles={files}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} md={12}>
              <ProductsAndServicesSearchField
                id="searchProductsAndServices"
                name="searchProductsAndServices"
                // required={false}
                required
                label={
                  isMobileDevice
                    ? 'Producto(s) o servicio(s)'
                    : 'Producto(s) o servicio(s) que deseas proteger'
                }
                variant="outlined"
                size="small"
                fullWidth
                className={clsx(classes.margin, classes.noLabel)}
                noOptionsText={
                  isMobileDevice
                    ? 'Ingresa un término'
                    : 'Ingresa un término para mostrar las coincidencias'
                }
                GroupIcon={ClassOutlinedIcon}
                Icon={WidgetsOutlinedIcon}
                InputIcon={SearchIcon}
                freeSolo={false}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Selecciona al menos un producto o servicio',
                  },
                  // https://github.com/react-hook-form/react-hook-form/issues/34
                  // https://github.com/react-hook-form/react-hook-form/issues/1617
                  validate: (value) => {
                    return (
                      (Array.isArray(value) && value?.length > 0) ||
                      'Selecciona al menos un producto o servicio'
                    )
                  },
                }}
                errors={errors}
                // multiple={false}
                multiple
                autoComplete
                disableCloseOnSelect
                includeInputInList={false}
                filterSelectedOptions={false}
                // filterSelectedOptions
                waitForFetch={200}
                classID={null}
                authenticated={authenticated}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                id="applicantKind"
                name="applicantKind"
                type="select"
                required
                variant="outlined"
                size="small"
                fullWidth
                className={clsx(classes.margin)}
                label="Tipo de persona"
                labelClassName={clsx(classes.margin, classes.noLabel)}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Selecciona el tipo de persona',
                  },
                }}
                errors={errors}
                options={kindOfPersonOptions}
              />
            </Grid>
            <Hidden xsDown>
              <Grid item sm={6} />
            </Hidden>
            {applicantKind ? (
              <>
                <Grid item xs={12} md={12}>
                  <FormField
                    id="applicantName"
                    name="applicantName"
                    label={
                      applicantKind === PHYSICAL_PERSON
                        ? 'Nombre(s)'
                        : 'Razón social'
                    }
                    type="text"
                    required
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={clsx(classes.margin, classes.noLabel)}
                    inputRef={register({
                      required: {
                        value: true,
                        message:
                          applicantKind === PHYSICAL_PERSON
                            ? 'Ingresa tu nombre'
                            : 'Ingresa tu razón social',
                      },
                      minLength: {
                        value: 3,
                        message: 'Debe ser mayor o igual a 3 caracteres',
                      },
                    })}
                    errors={errors}
                  />
                </Grid>
                {applicantKind === PHYSICAL_PERSON ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        id="applicantSurname"
                        name="applicantSurname"
                        label="Primer apellido"
                        type="text"
                        required
                        variant="outlined"
                        size="small"
                        fullWidth
                        className={clsx(classes.margin, classes.noLabel)}
                        inputRef={register({
                          required: {
                            value: true,
                            message: 'Ingresa tu primer apellido',
                          },
                          minLength: {
                            value: 2,
                            message: 'Debe ser mayor o igual a 2 caracteres',
                          },
                        })}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        id="applicantSecondSurname"
                        name="applicantSecondSurname"
                        label="Segundo apellido"
                        type="text"
                        required={false}
                        variant="outlined"
                        size="small"
                        fullWidth
                        className={clsx(classes.margin, classes.noLabel)}
                        inputRef={register({
                          required: {
                            value: false,
                            message: 'Ingresa tu segundo apellido',
                          },
                          minLength: {
                            value: 2,
                            message: 'Debe ser mayor o igual a 2 caracteres',
                          },
                        })}
                        errors={errors}
                      />
                    </Grid>
                  </>
                ) : null}
              </>
            ) : null}
          </Grid>
          <div className={classes.nextButtons}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-end"
              className={classes.nextButtonContainer}>
              <Grid
                item
                xs={10}
                sm={4}
                md={3}
                lg={2}
                className={classes.nextButton}>
                <Button
                  onClick={() => {
                    openCancelTradeMarkRegistrationModal()
                  }}
                  variant="contained"
                  color="secondary"
                  fullWidth>
                  Cancelar
                </Button>
              </Grid>
              <Grid
                item
                xs={10}
                sm={4}
                md={3}
                lg={2}
                className={classes.nextButton}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth>
                  Siguiente
                </Button>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </form>
  )
}

export default BrandData
