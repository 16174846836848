/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:74bdf680-0b53-474a-bd05-c36c9871063e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_6Y6lPsNTi",
    "aws_user_pools_web_client_id": "74fusg0tll5bpd61n3ocjpbesh",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://pszfmoxd4e.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "trademarkPlatformOpenPayApi",
            "endpoint": "https://zeigyf8iog.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "trademarkPlatformLocations",
            "endpoint": "https://j1vmfkpk86.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "trademarkPlatformEmailService",
            "endpoint": "https://d2by8yy1rj.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://2l657fiuencsbiwtugmydcrokq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "trademarkplatformf86941fadafb4f1094e702518094e8234429-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
