import React, { useState, useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Container,
  Button,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  FormControl,
  FormLabel,
  Typography,
  AppBar,
  Tab,
} from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import InfoIcon from '@material-ui/icons/Info'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import _ from 'lodash'
import { parse, format, isFuture } from 'date-fns'
import Title from '../../components/common/text/Title'
import { useTrademarkContext, useLayoutContext } from '../../components/context'
import {
  mapIcon,
  brandTypes,
  FormField,
  FileUpload,
  BrandTypeModal,
  ClassForm,
  withMainAuthenticator,
  FormFieldDateTimePicker,
} from '../../components'
import { useInfoModal } from '../../hooks'
import {
  readTrademarkApplication,
  moveTrademarkToStage,
  downloadFileFromS3,
  uploadFilesToS3,
  putBrandData,
  getCountries,
} from '../../services'
import {
  trademarkApplicationStages,
  emailContactMessages,
  MAILTO_ADDRESS,
} from '../../config'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  nextButtons: {
    marginTop: theme.spacing(2),
  },
  nextButtonContainer: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    alignItems: 'center',
  },
  nextButton: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1.5),
    },
  },
  noLabel: {
    marginTop: theme.spacing(1),
  },
  brandSelectIcon: {
    fontSize: theme.typography.fontSize,
    marginRight: theme.typography.fontSize,
  },
  endAdornment: {
    marginRight: theme.spacing(1.5),
  },
  containerOptions: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  formLabel: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    marginLeft: theme.spacing(0.5),
  },
  formOption: {
    textAlign: 'left',
    marginLeft: theme.spacing(1.5),
  },
  brandFieldsPanel: {
    padding: theme.spacing(0),
  },
}))

const FinalBrandData = () => {
  const classes = useStyles()

  const brandOptions = brandTypes.map((option) => {
    const { Icon } = mapIcon(option)
    return {
      value: option.id,
      label: option.name,
      icon: <Icon className={classes.brandSelectIcon} />,
    }
  })
  const {
    authenticated,
    setLoading,
    currentUser,
    setEmailContactStatus,
    setContactSpeedDialEnabled,
  } = useLayoutContext()
  const {
    setCurrentStep,
    trademarkApp,
    setTrademarkApp,
    trademarkAppID,
    brandClassesItems,
    classFormIsDirty,
    // eslint-disable-next-line no-unused-vars
    filesUploaded,
    setFilesUploaded,
  } = useTrademarkContext()
  const {
    register,
    handleSubmit,
    control,
    errors,
    watch,
    // setValue,
    reset,
  } = useForm()
  // } = useForm({
  //   defaultValues: {
  //     brandName: trademarkApp?.brandData?.name,
  //     brandType: trademarkApp?.brandData?.typeID,
  //     brandFiles: undefined,
  //     hasBeenUsed: trademarkApp?.brandData?.hasBeenUsed || false,
  //     dateOfInitialUse: trademarkApp?.brandData?.dateOfInitialUse,
  //     hasTranslation: trademarkApp?.brandData?.hasTranslation || false,
  //     translation: trademarkApp?.brandData?.translation,
  //     hasTransliteration: trademarkApp?.brandData?.hasTransliteration || false,
  //     transliteration: trademarkApp?.brandData?.transliteration,
  //   },
  // })
  const [selectedBrandType, setSelectedBrandType] = useState(null)
  const { openModal } = useInfoModal()

  const hasTransliterationWatched = watch('hasTransliteration')
  const hasTranslationWatched = watch('hasTranslation')
  const hasBeenUsedWatched = watch('hasBeenUsed')
  const [selectedTab, setSelectedTab] = React.useState('1')
  const [filesLoaded, setFilesLoaded] = React.useState(null)
  const [countryOptions, setCountryOptions] = React.useState(null)

  useEffect(() => {
    setContactSpeedDialEnabled(true)
    const email = emailContactMessages.authenticatedUserBrandData(
      trademarkApp?.brandData?.id,
      trademarkApp?.brandData?.name,
      trademarkApp?.owner
    )

    setEmailContactStatus({
      email: MAILTO_ADDRESS,
      subject: email?.subject,
      body: email?.message,
    })
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [emailContactMessages, setEmailContactStatus])

  const resetValues = ({
    brandName,
    brandType,
    brandFiles,
    hasBeenUsed,
    dateOfInitialUse,
    hasTranslation,
    translation,
    hasTransliteration,
    transliteration,
  }) => {
    reset({
      brandName,
      brandType,
      brandFiles,
      hasBeenUsed,
      dateOfInitialUse,
      hasTranslation,
      translation,
      hasTransliteration,
      transliteration,
    })
  }

  const retrieveTrademarkApplication = async () => {
    setLoading(true)
    let trademarkAppReaded
    const initialTrademarkAppReaded = await readTrademarkApplication({
      id: trademarkAppID,
      authenticated,
    })
    trademarkAppReaded = initialTrademarkAppReaded
    if (
      trademarkAppReaded.trademarkApplicationStage !==
      trademarkApplicationStages.supplementalData
    ) {
      trademarkAppReaded = await moveTrademarkToStage({
        trademarkApp: trademarkAppReaded,
        trademarkApplicationStage: trademarkApplicationStages.supplementalData,
        includeBrands: true,
        alterBrandData: true,
        authenticated,
      })
    }
    setTrademarkApp({ ...trademarkAppReaded })

    const selectedBrandTypeReaded = brandTypes.find(
      (b) => b.id === trademarkAppReaded?.brandData?.typeID
    )
    setSelectedBrandType(selectedBrandTypeReaded)

    let files
    if (
      selectedBrandTypeReaded?.requireFiles &&
      trademarkAppReaded?.brandData?.files
    ) {
      const retrievedFilesAsync = trademarkAppReaded?.brandData?.files?.map(
        async (f) => {
          return downloadFileFromS3({
            key: f.key,
            identityID: f.identityID,
            level: f.level,
            expires: 60,
          })
        }
      )
      const retrievedFiles = await Promise.all(retrievedFilesAsync)
      files = retrievedFiles
    } else {
      files = []
    }
    resetValues({
      brandName: trademarkAppReaded?.brandData?.name,
      brandType: trademarkAppReaded?.brandData?.typeID,
      brandFiles: files,
      hasBeenUsed: trademarkAppReaded?.brandData?.hasBeenUsed || false,
      dateOfInitialUse: trademarkAppReaded?.brandData?.dateOfInitialUse
        ? parse(
            trademarkAppReaded?.brandData?.dateOfInitialUse,
            'yyyy-MM-ddxxx',
            new Date()
          )
        : undefined,
      hasTranslation: trademarkAppReaded?.brandData?.hasTranslation || false,
      translation: trademarkAppReaded?.brandData?.translation,
      hasTransliteration:
        trademarkAppReaded?.brandData?.hasTransliteration || false,
      transliteration: trademarkAppReaded?.brandData?.transliteration,
    })
    // setValue('brandFiles', files)
    setFilesLoaded(files)
    setLoading(false)
  }

  const fetchCountries = useMemo(
    () =>
      _.throttle(async (request, callback) => {
        const data = await getCountries()
        const sortedData = _.sortBy(data, (item) => {
          return item.name
        })
        callback(sortedData)
      }, 1000),
    []
  )

  useEffect(() => {
    fetchCountries({}, (countries) => {
      const options = countries?.map((c) => {
        return {
          value: c?.code,
          label: c?.name,
        }
      })
      setCountryOptions(options)
    })
  }, [fetchCountries])

  // https://github.com/aws-amplify/amplify-js/issues/2903
  // https://github.com/aws/aws-sdk-js/issues/232
  // https://github.com/aws-amplify/amplify-js/issues/3236
  useEffect(() => {
    retrieveTrademarkApplication()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (brandClassesItems) {
      const trademarkAppUpdated = trademarkApp
      trademarkAppUpdated.brandClasses.items = brandClassesItems
      setTrademarkApp({ ...trademarkAppUpdated })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandClassesItems, setTrademarkApp])

  const handleSelectedTab = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const brandTypeAssigned = async (event) => {
    const { value } = event.target
    const selectBrandType = brandTypes.find((b) => b.id === value)
    setSelectedBrandType(selectBrandType)
  }

  const openInformationModal = ({ id, title, text, images }) => {
    openModal({
      title: '',
      content: (
        <BrandTypeModal id={id} title={title} text={text} images={images} />
      ),
      confirmationText: 'Cerrar',
      cancellationText: null,
    })
  }

  const onSubmit = async (data) => {
    setLoading(true)
    const {
      brandName,
      brandType,
      brandFiles,
      hasBeenUsed,
      dateOfInitialUse,
      hasTranslation,
      translation,
      hasTransliteration,
      transliteration,
    } = data

    const uploadedFiles = await uploadFilesToS3(
      brandFiles,
      authenticated,
      currentUser
    )
    setFilesUploaded(uploadedFiles)

    // eslint-disable-next-line no-unused-vars
    const updatedBrandData = await putBrandData({
      id: trademarkApp?.brandData?.id,
      name: selectedBrandType?.requireName ? brandName : '',
      typeID: brandType,
      files: uploadedFiles?.length > 0 ? uploadedFiles : undefined,
      hasBeenUsed,
      dateOfInitialUse: dateOfInitialUse
        ? format(dateOfInitialUse, 'yyyy-MM-ddxxx')
        : undefined,
      hasTranslation,
      translation,
      hasTransliteration,
      transliteration,
      _version: trademarkApp?.brandData?._version,
    })
    const trademarkAppReaded = await readTrademarkApplication({
      id: trademarkApp?.id,
      authenticated,
    })
    const trademarkAppUpdated = await moveTrademarkToStage({
      trademarkApp: trademarkAppReaded,
      trademarkApplicationStage:
        trademarkApplicationStages.supplementalApplicantData,
      includeBrands: true,
      alterBrandData: true,
      authenticated,
    })
    setTrademarkApp({ ...trademarkAppUpdated })
    setCurrentStep((prevActiveStep) => prevActiveStep + 1)
    setLoading(false)
  }

  const render = trademarkApp && filesLoaded && countryOptions
  if (!render) return null

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.root}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Title>Datos complementarios de la marca</Title>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                id="brandType"
                name="brandType"
                type="select"
                required
                variant="outlined"
                size="small"
                fullWidth
                className={clsx(classes.margin)}
                label="Tipo de marca"
                labelClassName={clsx(classes.margin, classes.noLabel)}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Selecciona un tipo de marca',
                  },
                }}
                errors={errors}
                options={brandOptions}
                // eslint-disable-next-line no-unused-vars
                renderValue={(selected) => {
                  // const brandType = brandTypes.find((b) => b.id === selected)
                  const { Icon: BrandIcon } = mapIcon(selectedBrandType)
                  return (
                    <span>
                      <BrandIcon className={classes.brandSelectIcon} />
                      {selectedBrandType.name}
                    </span>
                  )
                }}
                onChange={brandTypeAssigned}
                IconComponent={() => {
                  return null
                }}
                endAdornment={
                  <InputAdornment
                    position="end"
                    className={classes.endAdornment}>
                    <IconButton
                      aria-label="info"
                      size="small"
                      onClick={() => {
                        openInformationModal({
                          id: selectedBrandType.id,
                          title: selectedBrandType.name,
                          text: selectedBrandType.helperPopOverText,
                          images: selectedBrandType.helperImages,
                        })
                      }}>
                      <InfoIcon fontSize="inherit" />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
            <Hidden xsDown>
              <Grid item sm={6} />
            </Hidden>
            {selectedBrandType?.requireName ? (
              <Grid item xs={12} md={12}>
                <FormField
                  id="brandName"
                  name="brandName"
                  label="Nombre de tu marca"
                  required
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={clsx(classes.margin, classes.noLabel)}
                  inputRef={register({
                    required: {
                      value: true,
                      message: 'Ingresa el nombre de tu marca',
                    },
                  })}
                  errors={errors}
                  multiline
                  rows="4"
                />
              </Grid>
            ) : null}
            {selectedBrandType?.requireFiles ? (
              <Grid item xs={12} md={12}>
                <FileUpload
                  id="brandFiles"
                  name="brandFiles"
                  label="Archivos adjuntos"
                  required
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={clsx(
                    classes.margin,
                    classes.noLabel,
                    classes.brandFilesSelection
                  )}
                  acceptedFiles={['image/gif']}
                  filesLimit={5}
                  maxFileSize={5 * 1000 * 1000}
                  dropzoneText="Arrastra y suelta una imagen aquí o haz clic. Tamaño máximo 5MB por imagen. Máximo 5 archivos de imagen."
                  previewText="Archivos seleccionados"
                  showAlerts={false}
                  showPreviews
                  showPreviewsInDropzone={false}
                  showFileNames
                  showFileNamesInPreview
                  useChipsForPreview={false}
                  onChange={(f) => console.log('Files:', f)}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Selecciona al menos un archivo de imagen',
                    },
                    validate: (value) => {
                      return (
                        (Array.isArray(value) && value?.length > 0) ||
                        'Selecciona al menos un archivo de imagen'
                      )
                    },
                  }}
                  errors={errors}
                  initialFiles={filesLoaded}
                />
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl
              size="small"
              variant="outlined"
              className={clsx(classes.margin, classes.noLabel)}
              required={false}
              disabled={false}
              fullWidth>
              <Container className={clsx(classes.containerOptions)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel
                      component="legend"
                      className={clsx(
                        classes.margin,
                        classes.noLabel,
                        classes.formLabel
                      )}>
                      Descripción
                    </FormLabel>
                  </Grid>
                  {selectedBrandType?.requireFiles && (
                    <Grid item xs={12}>
                      <FormField
                        id="hasTransliteration"
                        name="hasTransliteration"
                        type="switch"
                        label={
                          <>
                            <Typography variant="body1" color="primary">
                              El nombre de tu marca incluye caracteres de otro
                              alfabeto
                              <IconButton
                                aria-label="info"
                                size="small"
                                onClick={() => {
                                  openInformationModal({
                                    id: 'hasTransliteration',
                                    title: 'Transliteración',
                                    text:
                                      'Ejemplos de marcas definidas en otros alfabetos.',
                                    images: [
                                      '/ayi-dumplings.png',
                                      '/pycckoe.png',
                                      '/arab-bank.png',
                                    ],
                                  })
                                }}>
                                <InfoIcon fontSize="inherit" />
                              </IconButton>
                            </Typography>
                          </>
                        }
                        variant="outlined"
                        size="small"
                        fullWidth
                        className={clsx(classes.margin, classes.formOption)}
                        labelClassName={clsx(classes.margin, classes.noLabel)}
                        control={control}
                        rules={{
                          required: {
                            value: false,
                            message: '',
                          },
                        }}
                        errors={errors}
                      />
                    </Grid>
                  )}
                  {hasTransliterationWatched && (
                    <Grid item xs={12} md={12}>
                      <FormField
                        id="transliteration"
                        name="transliteration"
                        label="Transliteración (opcional)"
                        required={false}
                        variant="outlined"
                        size="small"
                        fullWidth
                        className={clsx(classes.margin, classes.noLabel)}
                        inputRef={register({
                          required: {
                            value: false,
                            message: 'Ingresa la transliteración para tu marca',
                          },
                        })}
                        errors={errors}
                        multiline
                        rows="4"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormField
                      id="hasTranslation"
                      name="hasTranslation"
                      type="switch"
                      label={
                        <Typography variant="body1" color="primary">
                          El nombre de tu marca se encuentra en otro idioma
                          diferente al español
                        </Typography>
                      }
                      variant="outlined"
                      size="small"
                      fullWidth
                      className={clsx(classes.margin, classes.formOption)}
                      labelClassName={clsx(classes.margin, classes.noLabel)}
                      control={control}
                      rules={{
                        required: {
                          value: false,
                          message: '',
                        },
                      }}
                      errors={errors}
                    />
                  </Grid>
                  {hasTranslationWatched && (
                    <Grid item xs={12} md={12}>
                      <FormField
                        id="translation"
                        name="translation"
                        label="Traducción (opcional)"
                        required={false}
                        variant="outlined"
                        size="small"
                        fullWidth
                        className={clsx(classes.margin, classes.noLabel)}
                        inputRef={register({
                          required: {
                            value: false,
                            message: 'Ingresa la traducción para tu marca',
                          },
                        })}
                        errors={errors}
                        multiline
                        rows="4"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormField
                      id="hasBeenUsed"
                      name="hasBeenUsed"
                      type="switch"
                      label={
                        <Typography variant="body1" color="primary">
                          La marca se ha utilizado previamente
                        </Typography>
                      }
                      variant="outlined"
                      size="small"
                      fullWidth
                      className={clsx(classes.margin, classes.formOption)}
                      labelClassName={clsx(classes.margin, classes.noLabel)}
                      control={control}
                      rules={{
                        required: {
                          value: false,
                          message: '',
                        },
                      }}
                      errors={errors}
                    />
                  </Grid>
                  {hasBeenUsedWatched && (
                    <Grid item xs={12} sm={6}>
                      <FormFieldDateTimePicker
                        id="dateOfInitialUse"
                        name="dateOfInitialUse"
                        type="date"
                        label="Fecha en la que se inició a utilizar"
                        required
                        variant="dialog"
                        size="small"
                        fullWidth
                        className={clsx(classes.margin, classes.noLabel)}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              aria-label="info"
                              size="small"
                              onClick={() => {
                                openInformationModal({
                                  id: 'dateOfInitialUse',
                                  title: 'Fecha en la que se inició a utilizar',
                                  text:
                                    'Señala una fecha que, en caso de ser necesario, pudieras demostrar.',
                                  images: [],
                                })
                              }}>
                              <InfoIcon fontSize="small" />
                            </IconButton>
                          ),
                        }}
                        InputAdornmentProps={{ position: 'start' }}
                        control={control}
                        rules={{
                          required: {
                            value: hasBeenUsedWatched || false,
                            message:
                              'Ingresa la fecha en la que se inició a utilizar tu marca',
                          },
                          validate: {
                            futureDate: (value) => {
                              return (
                                !isFuture(value) ||
                                'La fecha debe ser menor o igual al dia actual'
                              )
                            },
                          },
                        }}
                        errors={errors}
                        inputVariant="outlined"
                        autoOk
                        disableFuture
                        format="dd/MM/yyyy"
                        invalidDateMessage="Fecha inválida"
                        invalidLabel="Fecha inválida"
                        mask="__/__/____"
                        cancelLabel="CANCELAR"
                        clearable
                        clearLabel="LIMPIAR"
                        okLabel="OK"
                        showTodayButton
                        todayLabel="HOY"
                      />
                    </Grid>
                  )}
                  <Hidden xsDown>
                    <Grid item sm={6} />
                  </Hidden>
                  <Grid item xs={12}>
                    <TabContext value={selectedTab}>
                      <AppBar
                        position="static"
                        color="default"
                        variant="outlined">
                        <TabList
                          value={selectedTab}
                          onChange={handleSelectedTab}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="scrollable"
                          scrollButtons="on"
                          // scrollButtons="auto"
                          aria-label="scrollable auto tabs classes">
                          {trademarkApp?.brandClasses?.items
                            ?.sort((a, b) => {
                              return Number(a.classID) - Number(b.classID)
                            })
                            .map((bc, index) => {
                              return (
                                <Tab
                                  key={bc.classID}
                                  label={`Clase ${bc.classID}`}
                                  value={`${index + 1}`}
                                  disabled={
                                    classFormIsDirty &&
                                    `${index + 1}` !== selectedTab
                                  }
                                />
                              )
                            })}
                        </TabList>
                      </AppBar>
                      {trademarkApp?.brandClasses?.items
                        ?.sort((a, b) => {
                          return Number(a.classID) - Number(b.classID)
                        })
                        .map((bc, index) => {
                          return (
                            <TabPanel
                              key={bc.classID}
                              value={`${index + 1}`}
                              className={classes.brandFieldsPanel}>
                              <ClassForm
                                brandClass={bc}
                                index={index}
                                countries={countryOptions}
                              />
                            </TabPanel>
                          )
                        })}
                    </TabContext>
                  </Grid>
                </Grid>
              </Container>
            </FormControl>
          </Grid>
          <div className={classes.nextButtons}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-end"
              className={classes.nextButtonContainer}>
              <Grid
                item
                xs={10}
                sm={4}
                md={3}
                lg={2}
                className={classes.nextButton}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={classFormIsDirty}>
                  Siguiente
                </Button>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </form>
  )
}

export default withMainAuthenticator(FinalBrandData)
