// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react'
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { I18n } from '@aws-amplify/core'
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import SettingsIcon from '@material-ui/icons/Settings'
import PaymentIcon from '@material-ui/icons/Payment'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import ReceiptIcon from '@material-ui/icons/Receipt'
import StepConnector from '@material-ui/core/StepConnector'
import Typography from '@material-ui/core/Typography'
// eslint-disable-next-line no-unused-vars
import { Container, Grid, Tooltip } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTrademarkContext, useLayoutContext } from '../../components/context'
import BrandTypeSelection from './BrandTypeSelection'
import BrandData from './BrandData'
import Payment from './Payment'
import AccountCreation from './AccountCreation'
import FinalBrandData from './FinalBrandData'
import ApplicantDetails from './ApplicantDetails'
import RequestSummary from './RequestSummary'
import { theme } from '../../config'

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: theme.palette.primary.dark,
      /* backgroundImage:
        'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(60,113,190,1) 50%, rgba(0,212,255,1) 65%)', */
    },
  },
  completed: {
    '& $line': {
      backgroundColor: theme.palette.primary.main,
      /* backgroundImage:
        'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(60,113,190,1) 50%, rgba(0,212,255,1) 65%)', */
    },
  },
  line: {
    height: 3,
    border: 0,
    // backgroundColor: '#eaeaf0',
    backgroundColor: theme.palette.grey[200],
    borderRadius: 1,
  },
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
  root: {
    // backgroundColor: '#ccc',
    backgroundColor: theme.palette.grey[300],
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    [theme.breakpoints.down('xs')]: {
      width: 40,
      height: 40,
    },
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: theme.palette.primary.dark,
    /* backgroundImage:
      'linear-gradient(136deg, rgba(2,0,36,1) 0%, rgba(60,113,190,1) 50%, rgba(0,212,255,1) 65%)', */
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor: theme.palette.primary.light,
    /* backgroundImage:
      'linear-gradient(136deg, rgba(2,0,36,1) 0%, rgba(60,113,190,1) 50%, rgba(0,212,255,1) 65%)', */
  },
})

const ColorlibStepIcon = (props) => {
  const classes = useColorlibStepIconStyles()
  const { active, completed, icon } = props
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'))

  const icons = {
    1: <SettingsIcon fontSize={isMobileDevice ? 'small' : 'default'} />,
    2: <PaymentIcon fontSize={isMobileDevice ? 'small' : 'default'} />,
    3: <AccountCircleIcon fontSize={isMobileDevice ? 'small' : 'default'} />,
    4: <LibraryBooksIcon fontSize={isMobileDevice ? 'small' : 'default'} />,
    5: <ContactMailIcon fontSize={isMobileDevice ? 'small' : 'default'} />,
    6: <ReceiptIcon fontSize={isMobileDevice ? 'small' : 'default'} />,
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}>
      {icons[String(icon)]}
    </div>
  )
}

ColorlibStepIcon.propTypes = {
  // eslint-disable-next-line react/require-default-props
  active: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  completed: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  icon: PropTypes.node,
}

// eslint-disable-next-line no-shadow
const useStyles = makeStyles((theme) => ({
  brandTypeSelection: {
    margin: theme.spacing(0),
    maxWidth: '100%',
  },
  alreadyACustomer: {
    fontSize: '12px',
    fontWeight: 'normal',
    color: theme.palette.secondary.light,
    textAlign: 'center',
  },
  enterHere: {
    fontSize: '15px',
    fontWeight: 'bold',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  stepperContainer: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  stepper: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}))

const steps = [
  { id: 0, label: 'Registro de marca', StepComponent: BrandData },
  { id: 1, label: 'Pago', StepComponent: Payment },
  { id: 2, label: 'Crear cuenta', StepComponent: AccountCreation },
  { id: 3, label: 'Datos de la marca', StepComponent: FinalBrandData },
  { id: 4, label: 'Datos del solicitante', StepComponent: ApplicantDetails },
]

const getStepContent = (currentStep) => {
  const step = steps.find((s) => s.id === currentStep)
  if (!step) return 'Unknown step'
  const { StepComponent } = step
  return StepComponent ? (
    <StepComponent showCancelButton={currentStep > 2 ? false : undefined} />
  ) : (
    'Unknown step'
  )
}

const TrademarkRegistrationFlowPage = () => {
  const {
    setLoading,
    setShowAuthUI,
    setAuthState,
    authenticated,
  } = useLayoutContext()
  const {
    brand,
    setBrand,
    currentStep,
    setCurrentStep,
    defaultBrandData,
    setFiles,
  } = useTrademarkContext()
  const classes = useStyles()
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'))

  const handleBrandTypeSelection = (brandType) => {
    setLoading(true)
    setTimeout(() => {
      setFiles(null)
      setBrand({
        generalData: {
          ...defaultBrandData?.generalData,
          brandType,
        },
      })
      setCurrentStep(0)
      setLoading(false)
    }, 200)
  }

  if (currentStep === 5) {
    return <RequestSummary />
  }

  const shouldRenderStepper =
    (authenticated && currentStep > 2) || currentStep < 3

  return (
    <>
      {brand?.generalData?.brandType ? (
        <Container>
          {shouldRenderStepper && (
            <Container className={classes.stepperContainer}>
              <Stepper
                className={classes.stepper}
                alternativeLabel
                activeStep={currentStep}
                connector={<ColorlibConnector />}
                orientation="horizontal">
                {/* eslint-disable-next-line no-unused-vars */}
                {steps.map(({ id, label }) => (
                  // <Step key={label}>
                  //   {!isMobileDevice ? (
                  //     <StepLabel StepIconComponent={ColorlibStepIcon}>
                  //       {label}
                  //     </StepLabel>
                  //   ) : (
                  //     <Tooltip title={label} arrow>
                  //       <StepLabel StepIconComponent={ColorlibStepIcon}>
                  //         {id === currentStep && label}
                  //       </StepLabel>
                  //     </Tooltip>
                  //   )}
                  // </Step>
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {!isMobileDevice && label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Container>
          )}
          {!authenticated && currentStep < 3 && (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center">
              <Grid item xs={4} sm={2} md={2} lg={1}>
                <Typography className={classes.alreadyACustomer}>
                  {I18n.get('If you are already a customer')}
                </Typography>
                <Typography
                  onClick={() => {
                    setShowAuthUI(true)
                    setAuthState('signIn')
                  }}
                  className={classes.enterHere}>
                  {I18n.get('Enter here')}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Container className={classes.container}>
            {getStepContent(currentStep)}
          </Container>
        </Container>
      ) : (
        <Container className={classes.brandTypeSelection}>
          <BrandTypeSelection onCardClick={handleBrandTypeSelection} />
        </Container>
      )}
    </>
  )
}

export default TrademarkRegistrationFlowPage
